import { ApiHandler } from "@/lib/ApiHandler";

class InfoBoxService extends ApiHandler {
  constructor () {
    super("/api/infoBoxes");
  }

  async list (idParent) {
    return await this.get(`?idParent=${idParent}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (idInfoBox, body) {
    return await this.put(`${idInfoBox}`, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async enable (id) {
    return await this.patch(`${id}/enable`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async disable (id) {
    return await this.patch(`${id}/disable`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

}

export const infoBoxService = new InfoBoxService();

